$(document).ready(function() {

  let nvc_wnet_underageredirect_modal = $('#nvc_wnet_underageredirect-modal');

  if (nvc_wnet_underageredirect_modal.length) {
    nvc_wnet_underageredirect_modal.modal('show').on('hide.bs.modal', function () {
      if ($(this).hasClass('show')) {
        return false;
      }
    });
  }

  $('#nvc_wnet_underageredirect_btn_success').on('click', function() {
    $.ajax({
      url: $(this).data('action'),
      method: 'POST'
    });

    nvc_wnet_underageredirect_modal.removeClass('show').modal('hide');
  });
});
