import prestashop from 'prestashop';
import $ from 'jquery';
import Cookies from 'js-cookie';

export default class GuestRegistrationModal {
    init() {
        this.showModalOnCartPage();
        this.listenBlockCartModalEvents();
    }

    showModalOnCartPage() {
        if (prestashop.page.page_name === 'cart' && this.isGuestRegistrationModalReadyToShow()) {
            this.toggleGuestRegistrationModal();
        }
    }

    listenBlockCartModalEvents() {
        this.setGuestRegistrationCookieModal();
        this.showModalAfterCloseBlockCartModal();
    }

    setGuestRegistrationCookieModal() {
        const $blockCartGuestRegistrationModal = this.getBlockCartGuestRegistrationModal();

        $(document).on('show.bs.modal', '#blockcart-modal', () => {
            if (!Cookies.get('guest-registration-modal') && $blockCartGuestRegistrationModal.length) {
                Cookies.set('guest-registration-modal', 0);
            }
        });
    }

    showModalAfterCloseBlockCartModal() {
        $(document).on('hidden.bs.modal', '#blockcart-modal', () => {
            this.toggleGuestRegistrationModal();
        });
    }

    toggleGuestRegistrationModal() {
        if (this.isGuestRegistrationModalReadyToShow()) {
            setTimeout((e) => {
                const $blockCartGuestRegistrationModal = this.getBlockCartGuestRegistrationModal();

                if ($blockCartGuestRegistrationModal.length) {
                    Cookies.set('guest-registration-modal', 1);
                    $blockCartGuestRegistrationModal.modal('show');
                }
            }, 1000);
        }
    }

    getBlockCartGuestRegistrationModal() {
        return $('.js-guest-registration-modal');
    }

    isGuestRegistrationModalReadyToShow() {
        return (Cookies.get('guest-registration-modal') !== 'undefined' && Cookies.get('guest-registration-modal') === '0');
    }

    getBlockCartModal() {
        return $('#blockcart-modal');
    }
}
